@font-face {
  font-family: "Computer Modern";
  src: url('https://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunss.otf');
}
@font-face {
  font-family: "Computer Modern";
  src: url('https://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunsx.otf');
  font-weight: bold;
}
@font-face {
  font-family: "Computer Modern";
  src: url('https://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunsi.otf');
  font-style: italic, oblique;
}
@font-face {
  font-family: "Computer Modern";
  src: url('https://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunbxo.otf');
  font-weight: bold;
  font-style: italic, oblique;
}

body {
  margin: 0;
  font-family: "Computer Modern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


